.form-inline {
    padding-top: 0px;

    &__container {
        display: flex;
        padding-bottom: 12px;
        > span {
            width: 33%;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        padding-right: 25px;
    }

    &__input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
    }
}