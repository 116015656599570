@import 'scss/globals';

.record-video-record {
  &__video-container {
    margin: 0 auto;
    border: 5px solid #4d49e1;
    color: #4d49e1;
    background-color: #fff;
    border-radius: 5px;
    video {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__timer {
    font-size: 2em;
    line-height: 1.5em;
    text-align: center;
  }

  &__timer-inner-with-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: $gap-smaller;

    svg {
      height: 0.7em;
      color: #d93025;
    }
  }

  &__error,
  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 600px;
    gap: $gap-medium;
    margin: 0 auto;
  }
}
