@import 'src/scss/globals';

.dashboard-certificate-header {
  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__language {
    width: fit-content;
  }
}


.dashboard-certificate {
  --wizard-box-padding: 30px;
  background: var(--colorNeutralBackground1);
  height: 100%;
  min-height: 700px; // fixed value to avoid "jumping" basing on what we have in the app so far
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .dashboard-layout__content-column--small & {
    min-height: 450px;
  }
}
