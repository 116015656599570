@import '../../Verification/Verification';

.dashboard-verification-intro--welcome {
  flex-direction: row !important;
  align-items: center;
  gap: 24px;
}

 .dashboard-verification-intro--content {
  display: flex;
  flex-direction: column;
  gap: 24px;
   ol {
     padding-left: 16px;
   }
}

.dashboard-verification-intro {
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 210px;
    border-radius: 24px;
    background-color: var(--colorBrandBackground2);
    svg {
      width: 90px;
      height: 90px;
      color: var(--icons-inperly-purplish-blue);
    }
  }
}

.dashboard-verification-intro--action-wrapper {
  display: flex;
  gap: 8px;
}
