.dashboard-deny-verification-wrapper {
  justify-content: center;
  &__step {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      text-align: center;
    }
  }
}

.deny-form {
  &__textarea {
    width: 500px;
    max-width: 100%;
    margin-top: 64px;
  }
}

.reject-verification-completed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;
}
