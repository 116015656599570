@import 'src/scss/globals';

.dashboard-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: calc(100vw - ($gap-large * 2));

  &__logo {
    height: 50px;
    width: auto;
  }
}
