@import 'scss/globals';

.record-video-intro {
  display: flex;
  flex-direction: column;
  gap: $gap-medium;
  justify-content: center;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;

  &__button-wrapper {
    text-align: center;
  }
}
