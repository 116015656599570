@import 'src/scss/globals';

.otp {
  &__label {
    color: var(--colorNeutralForeground2);
  }

  &__header-container {
    display: flex;
    flex-direction: column;
    gap: $gap-medium;
  }

  &__body-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__request-again-container {
    display: inline;
  }

  &__error {
    color: var(--colorPaletteRedForeground1);
  }

  &__icon {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}