@import 'src/scss/globals';

.dashboard-layout {
  display: grid;
  width: 100%;
  height: 100%;
  justify-items: center;

  & > * {
    grid-area: 1 / 1 / -1 / -1;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__background-colors {
    [app-theme="dark"] & {
      background: var(--colorNeutralForeground1Static);
    }
  }

  &__background-image {
    background: url('/media/background.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
    [app-theme="dark"] & {
      filter: grayscale(1) opacity(0.1);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-column {
      width: 100%;
      max-width: 1234px;
      display: flex;
      flex-direction: column;
      gap: $gap-large;

      &--small {
        max-width: 440px;
      }
    }
  }
}
