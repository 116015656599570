.reject-sign {
  &__message {
    width: 450px;
    max-width: 100%;
  }
}

.reject-completed {
  height: 100%;
}
