.country-flags-preloader {
  visibility: hidden;
  opacity: 0;
  height: 0px;
  width: 0px;
  overflow: hidden;
  position: fixed;
}

.flagicons-container {
  width: auto;
  height: 1em;
  display: inline-block;
}
