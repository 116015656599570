@import 'src/scss/globals';

.dashboard-signing {
  --wizard-box-padding: 30px;
  background: var(--colorNeutralBackground1);
  height: 100%;
  min-height: min(700px, 100vh); // fixed value to avoid "jumping" basing on what we have in the app so far
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .dashboard-layout__content-column--small & {
    min-height: 450px;
  }
}
