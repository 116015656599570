[app-theme='default'] {
  --icons-edo-app-red: #c81224;
  --icons-idnow-dot: #ff6b40;
  --icons-idnow-text: #1c1e39;
  --icons-mszafir-blue: #28328c;
  --icons-mszafir-cyan: #00a0c3;
  --icons-simply-cyan: #00a4e0;
  --icons-simply-black: #1d1d1b;
  --icons-inperly-purplish-blue: #4d49e1;
  --inperly-logo-logo: #494ce3;
  --inperly-logo-title: #242424;
  --inperly-logo-slogan: #616161;
}
