@import 'src/scss/globals';

.eideasy-callback {
  display: flex;
  flex-direction: column;
  gap: $gap-large;
  background: var(--colorScheme-colorNeutralBackground1);
  padding: $gap-large;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);

  &__header {
    display: flex;
    align-items: center;
    gap: $gap-medium;
  }

  &__header-logo {
    height: 50px;
    width: auto;
  }

  &__header-middle {
    flex-grow: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0;
  }

  &__check-icon {
    color: var(--colorScheme-colorBrandForeground1);
  }
}
