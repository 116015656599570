.simply-sign-auth {
  padding: 50px;
  background-color: var(--colorScheme-colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  gap: 25px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.15);

  &__header {
    color: var(--colorScheme-colorBrandForeground1);
    display: flex;
    align-items: center;
    gap: 25px;
  }

  &__subheader {
    overflow-wrap: break-word;
  }
}
